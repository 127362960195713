import AOS from 'aos';
import 'aos/dist/aos.css';
import './js/multi-modal.js';
import './js/form.js';
import './js/header-bg.js';
import './js/scroll-top.js';
import './js/openContact';
import './js/optimizate-map.js';

AOS.init();
